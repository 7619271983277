import React from 'react';
import Layout from '../components/Layout';

const Err = () => {
    return (
        <Layout>
            <div>
                <h3>404</h3>
                <p>Wrong page</p>
            </div>
        </Layout>
     );
}
 
export default Err;
